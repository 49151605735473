body{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 50px;
    overflow: hidden;
    background: #000;    
}

i{
    padding: 0.35em;
    font-size: 2em;
}

a{
  color: #fff;
}

a:hover{
  color: #fff;
  text-decoration: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.wrapper{
    display: flex;
    align-items: center;
    height: 100vh;
    justify-content: center;
    background-color: transparent;
    align-content: center;
    line-height: 1;
}

.container{    
    color: #fff;
    border-radius: 5em;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 10px;
}

.screen{
    padding:0em;
    width: 100vw;
}

.panel{
      
    width: 85VW;
    max-height: 450px;
    margin: 13px 0;
}

.half-panel{
  width: 50vw;
}

.centre{
    text-align: center;
    justify-content: center;
    align-items: center;
}

.left{
    text-align: left;
    justify-content: left;
    align-items: left;
}

.dropdown{
    width: 50vw;
    font-size:0.5em;
}

#time-container span{
    display: block;
}

.flex{
    display: flex;
}

.flex-rows{
    flex-direction: row;
}

.flex-columns{
    flex-direction: column;
}

.large-font{
    font-size: 1.5em;
}

.small-font{
    font-size:0.5em;
}

.tiny-font{
    font-size:0.33em;
}

.moon{
    font-size: 2em;
}

.forecast{
    width: 75vw;
    min-height: 266px;
    justify-content: flex-start;
    overflow-y: hidden;
}

.forecast-day{
    display: inline-block;
    margin: 5px;
    min-width: 95px;
}

.forecast-day span{
    display: block;
}

.panel::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.collapse{   
    min-height: 980px;  
    max-height: 980px;  
    transition: min-height 0.5s ease-in-out,max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 100;
    width: 80%
}

.collapse.hide{
    max-height: 0;
    min-height: 0;
    transition: min-height 0.5s ease-in-out,max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0;
    pointer-events: none;
}

.collapse.small{
    min-height: 900px;  
    max-height: 900px;  
    transition: min-height 0.5s ease-in-out,max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 100;
    width: 100%
}

.collapse div.panel{
    z-index: 9999999999;
}

.switch-screen-button{
    position: absolute;
    right: 15px;
}

.switch-screen-button.top{
    top:15px;
}

.switch-screen-button.bottom{
    bottom: 15px;
}

.button{
    color: #333;
    background: transparent;
    border: 0;
    cursor: pointer;
    font-size: 1em;
}

.colour-picker{
    position: absolute;
    right: -8px;
    top: -8px;
    width: 56px;
    height: 56px;
    border: none;
    cursor: pointer;
}

.colour-input-container {
    position: relative;
    overflow: hidden;
    width: 40px;
    height: 40px;
    border: solid 2px #ddd;
    border-radius: 40px;
  }

  .status-small{
    padding: 1em;
    width: 85vw;
  }

  .extra-display{
    width:100%;
  }

  .extra-item{
    padding: 0.5em;
  }

  .extra-item label{
    display: block;
    margin-right: unset;
    font-style: oblique;
  }

/* tasks needs redoing props */


  
  .form-group label{
    margin-right: 1rem;
  }
  
  .form-group input,
  .form-group select,
  .form-group textarea{
    border-width: 2px;
    border-style: solid;
    border-color: #000;
    background-color: #efefef;
    padding:1rem;
    font-size:1rem;
    width: 100%;
  }
  
  .form-group button{
    border: none;
    background-color:#ff7f50;
    color:#333;
    padding: 0.5rem;
    width: 8rem;
    margin-left: 1rem;  
    cursor: pointer;
    margin-top: 1rem;
  }
  
  .action-button{
    border: none;
    background: transparent;
    padding: 1vw;
    color:#fff;
    cursor: pointer;
  }
  
  .button-column{
    text-align: center;
  }
  
  .tag-list{
    list-style-type: none;
  }
  
  .tag-list li{
    display: inline-block;
  }
  
  .tag-label{
    margin-left: 0.5rem;
    padding:0.5rem;
    border: solid 1px;
    color:#333;
    background-color: #cdcdcd;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */ 
    user-select: none; /* Standard */
  }
  
  .tag-list li input{
    width: unset;
    position: absolute;
    left: -9999px;
  }
  
  input:checked + label {
    background-color: #ff7f50;
    color: #FFF;
  }
  
  td .tag-label{
    display: inline-block;
    background-color: #ff7f50;
    color: #FFF;  
    cursor: default;
  }
  
  tfoot{
    text-align: left;
  }
  
  .sortButton{
    background-color: transparent;
    color: #fff;
    border:none;
    cursor: pointer;
  }
  
  button:active, button:focus {
    outline: none;
    border: none;
  }
  
  .table-tools-row{
    text-align: left;
    padding: 1rem;
  }
  
  label{
    margin-right:0.5rem;
    margin-left:0.5rem;
  }
  
  .tools-wrapper{
    display: flex;    
    justify-content: flex-end;
  }
  
  .tools-wrapper .form-group{
    margin-left: 1rem;
    padding: 1rem;
  }
  
  .tools-wrapper .form-group input,
  .tools-wrapper .form-group select{
    width: unset;
    padding: 0.5rem;
  }
  
  .tools-wrapper div:first-child{
    margin-right: auto;
    margin-left: unset;
  }
  
  .collapse-panel{
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s ease-out;  
  }
  
  .collapse-panel.active{
    max-height: 1200px;
    transition: max-height 0.5s ease-in-out;
    padding: 1rem;
  }
  
  button[type="reset"]{
    background-color: #cdcdcd;
  }

  .hide{
    display: none;
  }

  .fill-width{
    width: 100%;
  }

  .task-table{
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    display: block;
  }

  .task-table thead, tbody tr{
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .task-table thead {    
    width: calc( 100% - 1em)
  }

  .task-table tbody{
    display: inline-block;
    max-height: 700px;
    overflow: auto;
    width: 100%;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  #todays-tasks .task-table tbody{
    max-height: 450px;
  }

  
  .task-table tbody::-webkit-scrollbar {
    display: none;
  } 

  .tidy-scroll{
    overflow-y: clip;
    overflow-x: hidden;
    justify-content: unset;
  }
  
